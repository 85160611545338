@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");
/* font */
.black-han-sans-regular {
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.active {
  text-decoration: underline;
  text-underline-offset: 8px;
  font-weight: bold;
  text-decoration-color: #ea580c;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.bg-naver {
  background-color: #03c75a;
}

/* iframe */
#area {
  position: relative;
  width: 70%;
  padding-bottom: 56.25%;
}

#video {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./images/bg-wave.png");

  max-height: calc(100vh - 81px);
  /* background-position: 50% 50%; */
}

.bg-image:after {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)),
    url("./images/bg-wave.png");
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  height: calc(100vh - 81px);
  left: 0;
  right: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.download-app {
  width: fit-content;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 10px;

  display: flex;
  align-items: flex-start;
}

/* 카카오 공유하기 */
.share-kakao {
  width: fit-content;
  border-radius: 10px;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 8px 10px;
  width: 160px;
  margin-top: 10px;
  background-color: #fee500;
  font-size: 13px;

  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 메인로고 theme color */
.main-logo {
}
